<template>
  <div class="container">
    <div class="big_title">职业技能考试模拟训练系统</div>

    <div class="content_box" v-if="data_loaded">
      <div v-for="(book_class_item,idx1) in book_class" :key="idx1">
        <div class="title">{{book_class_item.class_name}}</div>
        <van-grid v-for="(book,idx2) in book_class_item.books" :key="idx2">
          <van-grid-item icon="records" :text="book.book_name" @click="router_book(book.id)" />
        </van-grid>
      </div>
      

      <div class="title">计算机考试</div>
      <van-grid>
        <van-grid-item icon="records" text="一级" @click="not_ready()" />
        <van-grid-item icon="records" text="二级VB" @click="not_ready()" />
        <van-grid-item icon="records" text="二级Delphi" @click="not_ready()" />
        <van-grid-item icon="records" text="二级C" @click="not_ready()" />
      </van-grid>

      <div class="title">少儿机器人考试</div>
      <van-grid>
        <van-grid-item icon="records" text="一级" @click="not_ready()" />
        <van-grid-item icon="records" text="二级" @click="not_ready()" />
        <van-grid-item icon="records" text="三级" @click="not_ready()" />
        <van-grid-item icon="records" text="四级" @click="not_ready()" />
        <van-grid-item icon="records" text="五级" @click="not_ready()" />
      </van-grid>
    </div>
  </div>
</template>
<script>
import { Grid, GridItem, Toast } from "vant";
import { get_book } from "@/api/practice/api";
export default {
  name: "Index",
  data() {
    return {
      data_loaded: false,
      book_class:[]
    };
  },
  created() {},
  watch: {
    $router: {
      handler: "req_get_book",
      //调用方法
      immediate: true
      //进入立即执行一次
    }
  },
  methods: {
    router_book(id) {
      this.$store.commit("set_book_id",id)
      this.$router.push({ path: "/book?ts="+Math.random()});
    },
    req_get_book() {
      this.data_loaded=false
      get_book().then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        this.data_loaded=true
        this.book_class=res.data.book_class
      });
    },
    not_ready() {
      Toast("该栏目正在准备中，敬请期待");
    }
  },
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Toast.name]: Toast
  }
};
</script>
<style lang="stylus">
.big_title{
    margin: 0;
    padding: 28px 0;
    color: #fff;
    font-size: 18px;
    line-height: 16px;
    background: #ff5859;
    text-align: center;
    height: 43px;
    font-weight: 600;
}
.content_box{
    width: 100%;
    height: 27px;
    background: #fff;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    top: -25px;
}
.title{
    margin: 0;
    padding: 32px 16px 16px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}
</style>
