import request from '@/utils/request'

export function get_book(data) {
    return request({
        url: '?c=practice_api&m=get_book',
        method: 'get',
        data
    })
}

export function get_unit(data) {
    console.log(data);
    return request({
        url: '?c=practice_api&m=get_unit',
        method: 'get',
        params:data,
        data
    })
}

export function get_set(data) {
    return request({
        url: '?c=practice_api&m=get_set',
        method: 'get',
        params:data,
        data
    })
}

export function get_set_questions(data) {
    return request({
        url: '?c=practice_api&m=get_set_questions',
        method: 'post',
        data
    })
}

export function submit_set_answer(data) {
    return request({
        url: '?c=practice_api&m=submit_set_answer',
        method: 'post',
        data
    })
}

export function favorite_question(data) {
    return request({
        url: '?c=practice_api&m=favorite_question',
        method: 'post',
        data
    })
}

export function favorite_question_cancel(data) {
    return request({
        url: '?c=practice_api&m=favorite_question_cancel',
        method: 'post',
        data
    })
}

export function get_unit_questions(data) {
    return request({
        url: '?c=practice_api&m=get_unit_questions',
        method: 'post',
        data
    })
}

export function submit_question_wrong(data) {
    return request({
        url: '?c=practice_api&m=submit_question_wrong',
        method: 'post',
        data
    })
}